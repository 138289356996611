import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Send, Add} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {TextField, Card, CardMedia, FormControlLabel, Checkbox} from '@mui/material';
import sample from '../../user.jpg'
import {GetSpecificUser, EditUser, CreateUser} from "../AuthService/apiUrls";
import Textarea from "../emptytextarea";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "38%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "auto",
};

export default function UsersModal({action, id, onUserUpdated}) {

    const [emailError, setEmailError] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [imageSrc, setImageSrc] = React.useState(null);

    const [name, setName] = React.useState('');
    const [mobile_no, setMobileNo] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [zipcode, setZipcode] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [fileImage, setFileImage] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false);

    const [message, setMessage] = React.useState('');

    const [snackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');


    React.useEffect(() => {
        if (action === 'edit' && open) {
            GetSpecificUser(id)
                .then(response => {
                    setImageSrc(response.profile_url);
                    setName(response.name);
                    setMobileNo(response.mobile_no);
                    setEmail(response.email);
                    setGender(response.gender);
                    setCountry(response.country);
                    setState(response.state);
                    setCity(response.city);
                    setZipcode(response.zipcode);
                    setAddress(response.address);
                    setIsAdmin(response.admin_status);
                    setFileImage(null);

                })
                .catch(error => {
                    console.error('Error:', error); // Handle errors if the Promise is rejected
                });
        }

    }, [action, id, open]);

    const handleSnackClick = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackBar(true);
        setTimeout(() => {
            setSnackBar(false);
        }, 5000);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setFileImage(file)
        }
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Invalid email format';
        }
        return '';
    };
    const handleChange = (field, value) => {
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'mobile_no':
                setMobileNo(value);
                break;
            case 'email':
                setEmail(value);
                setEmailError(validateEmail(value));
                break;
            case 'gender':
                setGender(value);
                break;
            case 'country':
                setCountry(value);
                break;
            case 'state':
                setState(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'zipcode':
                setZipcode(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'admin_status':
                let val = false
                if (value === "on") {
                    val = true
                }
                setIsAdmin(val);
                break;
            default:
                break;
        }
    };

    const handleTextareaChange = (value) => {
        setAddress(value);
    };

    var FormApi = CreateUser
    if (action === 'edit') {
        FormApi = EditUser
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!name || !mobile_no || !email) {
            setMessage('Please fill in all the required fields (*)');
            handleSnackClick('Please fill in all the required fields (*)', 'error');
            // Remove the message after 5 seconds
            setTimeout(() => {
                setMessage('');
            }, 5000);
            return;
        }


        const jsonData = {
            name: name,
            mobile_no: mobile_no,
            email: email
        };

        let FormApiData = JSON.stringify(jsonData);
        if (action === 'edit') {
            // Create FormData object
            const formData = new FormData();

            // Append file input value
            formData.append('image', fileImage);

            // Append text field values
            formData.append('name', name);
            formData.append('mobile_no', mobile_no);
            formData.append('email', email);
            formData.append('country', country);
            formData.append('gender', gender);
            formData.append('state', state);
            formData.append('city', city);
            formData.append('zipcode', zipcode);
            formData.append('address', address);
            formData.append('admin_status', isAdmin);

            FormApiData = formData
        }
        FormApi(FormApiData, id)
            .then(response => {
                setImageSrc(null);
                setName('');
                setEmail('');
                setMobileNo('');
                setGender('');
                setCountry('');
                setState('');
                setCity('');
                setZipcode('');
                setAddress('');
                setIsAdmin(false);

                setFileImage(null);
                handleClose()
                handleSnackClick(response.message, 'success');
            })
            .catch(error => {
                const errorData = error.response.data.message
                handleSnackClick(errorData, 'error');// Handle errors if the Promise is rejected
            });
        onUserUpdated();
    };

    return (
        <div style={{width: 30}}>
            <>
                {action === 'add' && (
                    <Button onClick={handleOpen} variant="contained" startIcon={<Add/>}>
                        Add
                    </Button>
                )}

                {action === 'edit' && (
                    <IconButton onClick={handleOpen} aria-label="edit">
                        <EditIcon/>
                    </IconButton>
                )}

            </>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={action === "edit" ? {...style, height: "90%"} : {...style}}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">

                        {action === "edit" ? "Edit" : "Add"} User
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {m: 1, width: '45%'},
                        }}
                        noValidate
                        autoComplete="off">
                        {action === "edit" &&
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 3}}>
                                <Card sx={{maxWidth: 300}}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={imageSrc || sample}
                                        alt="green iguana"
                                    />
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        size="large">
                                        <input hidden accept="image/*" type="file" onChange={handleImageUpload}/>
                                        <PhotoCamera fontSize="large"/>
                                    </IconButton>
                                </Card>
                            </Box>
                        }
                        <TextField id="standard-basic" label="Name" variant="standard" required value={name}
                                   onChange={(e) => handleChange('name', e.target.value)}/>
                        <TextField id="standard-basic" label="Mobile No" variant="standard" required
                                   value={mobile_no} type="number"
                                   onChange={(e) => handleChange('mobile_no', e.target.value)}/>
                        <TextField id="standard-basic" label="E-mail" variant="standard" required value={email}
                                   type="email" error={!!emailError}
                                   helperText={emailError}
                                   onChange={(e) => handleChange('email', e.target.value)}/>
                        {action === "edit" &&
                            <>

                                <FormControl variant="standard" sx={{width: "50%", my: 1}}>
                                    <InputLabel id="standard-basic">Gender</InputLabel>
                                    <Select
                                        id="standard-basic"
                                        value={gender}
                                        onChange={(e) => handleChange('gender', e.target.value)}
                                        label="Gender"
                                    >
                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField id="standard-basic" label="country" variant="standard" value={country}
                                           onChange={(e) => handleChange('country', e.target.value)}/>
                                <TextField id="standard-basic" label="state" variant="standard" value={state}
                                           onChange={(e) => handleChange('state', e.target.value)}/>
                                <TextField id="standard-basic" label="city" variant="standard" value={city}
                                           onChange={(e) => handleChange('city', e.target.value)}/>
                                <TextField id="standard-basic" label="Zipcode" variant="standard"
                                           value={zipcode}
                                           onChange={(e) => handleChange('zipcode', e.target.value)}/>
                                {name && <FormControlLabel required control={<Checkbox defaultChecked={isAdmin}/>}
                                                           label="Admin"
                                                           onChange={(e) => handleChange('admin_status', e.target.value)}/>
                                }
                            </>
                        }
                        <p style={{color: 'red'}}>{message}</p>
                    </Box>
                    {action === "edit" &&
                        <Box sx={{marginTop: 3}}>
                            <Typography variant="h6" component="h2">
                                Address
                            </Typography>

                            <Textarea aria-label="empty textarea" minRows={2} placeholder="Address"
                                      value={address} onChange={(e) => {
                                handleTextareaChange(e.target.value)
                            }}/>
                        </Box>}
                    <Button sx={{margin: 2}} variant="contained" startIcon={<Send/>} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
            <Box sx={{width: 500}}>

                <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        severity={snackSeverity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    )
        ;
}