import React, {useState, useEffect} from 'react';
import {Container, Form, Button} from 'react-bootstrap';
import "./login.css"
import login from '../login.jpg'
import {VerifyOTP, verifyEmail} from './AuthService/apiUrls';
import {storeTokens} from './AuthService/tokenFunctions';
import {useNavigate} from 'react-router-dom';
import {MuiOtpInput} from 'mui-one-time-password-input'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {Box} from "@mui/material";

function Login() {
    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isOtp, setIsOtp] = useState(false);
    const [snackBar, setSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');
    const [timer, setTimer] = useState(60); // 60 seconds countdown
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1); // Decrease by 1 every second
            }, 1000);
        } else {
            setIsDisabled(true); // Disable OTP input and show resend button when timer hits 0
        }

        return () => {
            clearInterval(interval); // Cleanup the interval on component unmount or when timer reaches 0
        };
    }, [timer]);


    const handleResendOtp = () => {
        setTimer(60); // Reset the timer to 60 seconds
        setIsDisabled(false); // Enable OTP input
        OtpSend(); // Call the resend OTP function
    };
    const handleSnackClick = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackBar(true);
        setTimeout(() => {
            setSnackBar(false);
        }, 5000);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };


    const validateEmail = (input) => {
        // Regular expression for basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(input);
    };

    const handleEmailChange = (e) => {
        const input = e.target.value;
        setEmail(input);
        setIsValidEmail(validateEmail(input));
    };

    function OtpSend() {
        verifyEmail({email})
            .then(response => {
                setIsOtp(true)
                handleSnackClick(response.message, 'success');
            })
            .catch(error => {
                const errorData = error.response.data.message
                handleSnackClick(errorData, 'error');// Handle errors if the Promise is rejected
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Assuming loginAPI returns a Promise
        if (isOtp) {
            VerifyOTP({email, otp})
                .then(response => {
                    storeTokens(response.access_token, response.refresh_token);
                    handleSnackClick(response.message, 'success');
                    navigate('/')
                })
                .catch(error => {
                    const errorData = error.response.data.message
                    handleSnackClick(errorData, 'error');// Handle errors if the Promise is rejected
                });
        } else {
            OtpSend()

        }


    };

    useEffect(() => {
        document.body.classList.add('login-body'); // Apply the class to the body element
        return () => {
            document.body.classList.remove('login-body'); // Remove the class when the component unmounts
        };
    }, []);

    return (
        <>
            <Box sx={{width: 500}}>

                <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        severity={snackSeverity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Container className='p-0 login-container'>

                <div className='container__svgs'>
                    <img className='container__svg1' src={login} alt="admin"></img>
                </div>
                <div className='container__content'>
                    <h2>Jaiwin Admin</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className="">Email</Form.Label>
                            <Form.Control
                                className='container__inputField mb-1'
                                type="text"
                                value={email}
                                onChange={handleEmailChange}
                                required
                            />
                            {!isValidEmail && (
                                <p style={{color: 'red'}} className='mb-2'>Please enter a valid email address</p>
                            )}
                        </Form.Group>
                        {isOtp &&
                            (<Form.Group>
                                <Form.Label className="">OTP</Form.Label>
                                <MuiOtpInput sx={{margin: 2}} length={6} value={otp} TextFieldsProps={{size: 'small'}}
                                             onChange={(e) => setOTP(e)}/>
                                <div style={{marginLeft:10}}>Time remaining: {timer} seconds</div>
                            </Form.Group>)}
                        {isDisabled ? (
                            <Button onClick={handleResendOtp}>Resend OTP</Button> // Show resend button when timer reaches zero
                        ) : (
                            <>

                                <Button type="submit" variant="outline-primary" style={{marginLeft: 20, marginTop: 10}}>
                                    {isOtp ? 'Verify OTP':'Send OTP '}
                                </Button>
                            </>  // Display countdown
                        )}


                    </Form>

                </div>

            </Container>
        </>
    );
};

export default Login