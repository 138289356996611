// api.js

import axios from 'axios';
import {getAccessToken, clearTokens} from './tokenFunctions';

const prod = true

const baseURL = prod ? 'https://backend.jaiwin.co.in/' : 'http://localhost:5000/'

const api = axios.create({
    baseURL: baseURL
});

const makeRequest = async (method, url, data = null, authenticate = true,json=true) => {
    const contentType = json ? 'application/json'  : 'multipart/form-data'
    try {
        const headers = {'Content-Type': contentType};
        if (authenticate) {
            const accessToken = getAccessToken();
            headers.Authorization = `Bearer ${accessToken}`;
        }
        const response = await api({
            method,
            url,
            data,
            headers,
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            const {status} = error.response;
            if (status === 401) {
                clearTokens();
                throw new Error('Unauthorized');
            }


            if (status === 422) {
                throw new Error('Bad Request');
            }

            if (status === 500) {
                throw new Error('Internal Server Error');
            }
        }
        throw error;
    }
};

export default makeRequest