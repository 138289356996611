import React from 'react';
import JaiwinNavbar from './navbar/navbar';
function Home() {

  return (
   <>
   <JaiwinNavbar/>
   <center>
    <h3>
      Welcome to home
    </h3>
   </center>
   </>
  );
};

export default Home