import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AuthService from './components/AuthService/AuthService';
import React, { useEffect } from 'react';
import LetterPad from './components/letterpad/letterpad';
import Login from './components/login';
import Home from './components/home';
import Billing from './components/billing/billing';
import Notifications from './components/notifications/notification';
import Products from './components/products/product';
import Users from "./components/users/users";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await AuthService.handleAuth();
      // setIsAuthenticated(isAuthenticated);

      if (!isAuthenticated) {
        navigate('/login');
      }
    };

    checkAuth();
  }, [navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/letterpad" element={<LetterPad />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/products" element={<Products />} />
        <Route path="/users" element={<Users />} />

      </Routes>
    </div>
  );
}

export default App;
