import {React, useEffect, useState} from 'react';
import JaiwinNavbar from '../navbar/navbar';
import './users.css'
import {Container} from 'react-bootstrap';
import {Stack, Box} from '@mui/material';
import UsersModal from "./usersModal";
import {DeleteSpecificUser, GetUsers} from "../AuthService/apiUrls";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";


function Users() {
    const [refresh, setRefresh] = useState(false);
    const triggerRefresh = () => {
        setRefresh(!refresh); // Toggle the refresh state to re-trigger the effect
    };
    const handleDelete = (id) => {
        DeleteSpecificUser(id)
            .then(response => {
                handleSnackClick(response.message, 'success');
                triggerRefresh()
            })
            .catch(error => {
                handleSnackClick(error.message, 'error');
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });

    };


    const [usersData, setUsersData] = useState([]);
    useEffect(() => {

        GetUsers()
            .then(response => {
                setUsersData(response)
            })
            .catch(error => {
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });

    }, [refresh]);

    const [snackBar, setSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');


    const handleSnackClick = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackBar(true);
        setTimeout(() => {
            setSnackBar(false);
        }, 5000);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };

    const columns = [
        {field: 'name', headerName: 'Name', width: 250}, {
            field: 'email',
            headerName: 'E-Mail ID',
            width: 250
        }, {
            field: 'mobile_no', headerName: 'Mobile Number', width: 200
        }, {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false, disableColumnMenu: true,

            renderCell: (params) => {

                return (<><IconButton onClick={() => handleDelete(params.id)}
                                      aria-label="delete">
                    <DeleteIcon/>
                </IconButton><UsersModal action='edit' id={params.id} onUserUpdated={triggerRefresh}/></>)
            },
        },


    ];


    return (<>
        <Box sx={{width: 500}}>

            <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    severity={snackSeverity}
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>
        </Box>
        <div className="row">
            <div className="col-lg-2">
                <JaiwinNavbar/>
            </div>
            <div className="col-lg-10">
                <Stack spacing={2} direction="row"
                       justifyContent="flex-end"
                       sx={{marginBottom: 5, marginTop: 3, marginRight: 10}}
                       alignItems="center">
                    <UsersModal action='add' onUserUpdated={triggerRefresh}/>

                </Stack>
                <Container>
                    <h3>Users</h3>

                    <div style={{width: '75%'}}>
                        <DataGrid
                            rows={usersData}
                            columns={columns}
                            autoHeight={true}
                            autosizeOnMount={true}
                            autosizeOptions={{expand: true}}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 5},
                                },
                            }}
                            pageSizeOptions={[5, 10]}

                        />
                    </div>

                </Container>
            </div>
        </div>


    </>);
};


export default Users