import 'bootstrap/dist/css/bootstrap.min.css';
import '../navbar/navbar.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {clearTokens} from '../AuthService/tokenFunctions';
import { useNavigate } from 'react-router-dom';
function JaiwinNavbar() {
     const navigate = useNavigate()
    const handleLogout = () => {
        clearTokens();
        navigate('/login')
    };
    return (
        <>

            <div className="navBg pl-0 pr-0">
                <nav className="nav navbar-toggleable-sm">
                    <div className="navbar flex-column mt-md-0 pt-md-0 p-0 w-100" id="navbarWEX">
                        <h1 style={{position: 'relative'}}>
                            JAIWIN
                            <button
                                className="btn btn-sm bg-body-secondary"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 10,
                                    marginTop: '25px', // Adjust margin top as needed for spacing
                                }}
                                onClick={handleLogout}
                            >
                                <FontAwesomeIcon icon={faSignOutAlt}/> Logout
                            </button>
                        </h1>

                        <a href="/" className="nav-link"><i className="fa fa-dashboard"></i><span>Dashboard </span></a>
                        <a href="/billing" className="nav-link"><i className="fa fa-music"
                                                                   aria-hidden="true"></i><span>Billing</span></a>
                        <a href="/letterpad" className="nav-link"><i className="fa fa-file-text-o"
                                                                     aria-hidden="true"></i><span>LetterPad</span></a>
                        <a href="/notifications" className="nav-link"><i className="fa fa-commenting-o"
                                                                         aria-hidden="true"></i><span>Notifications</span></a>
                        <a href="/products" className="nav-link"><i className="fa fa-plug"
                                                                    aria-hidden="true"></i><span>Products</span></a>
                        <a href="/users" className="nav-link"><i className="fa fa-users"
                                                                    aria-hidden="true"></i><span>Users</span></a>
                    </div>
                </nav>
            </div>


        </>
    );
}

export default JaiwinNavbar;